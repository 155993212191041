import React from 'react'

import { getErrorData } from '../utils/prismicRouteHelpers'
import { IErrorPage } from '../prismicTypes'
import MainGridContainer from '../components/grid/MainGridContainer'
import styled, { css } from 'styled-components'
import Image from 'next/image'
import PrismicRichText from '../components/PrismicRichText'
import Typography from '../styles/typography'

interface IProps {
  error: IErrorPage.IProps
}

const Index: React.FC<IProps> = ({
  error: {
    data: { title, description, image },
  },
}) => {
  return (
    <MainGridContainer extraStyle={SCGrid}>
      <SCContent>
        <SCTitle>{title}</SCTitle>
        <PrismicRichText extraParagraphStyle={SCDescription} richText={description} />
      </SCContent>
      <SCImage>
        <Image src={image.url} height={500} width={800} alt="ErrorImage" />
      </SCImage>
    </MainGridContainer>
  )
}

export const getStaticProps = async () => {
  const {
    props: { error, navigation, footer },
  } = await getErrorData('')

  return {
    props: { error, navigation, footer },
  }
}

export default Index

const SCGrid = css`
  height: 100vh;
`

const SCContent = styled.div`
  ${({ theme: { breakpoints } }) => css`
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    grid-column: 1 / -1;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-column: span 3;
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-column: 2 / 6;
      margin-top: 0;
    }
  `}
`

const SCImage = styled.div`
  ${({ theme: { breakpoints } }) => css`
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-column: span 3;
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-column: 7 / 13;
      margin-top: 0;
    }
  `}
`

const SCTitle = styled.h1`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h1}
    margin-bottom: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 3rem;
    }
  `}
`

const SCDescription = css`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.pBody}
    margin-bottom: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 3rem;
    }
  `}
`
